<template>
  <div class="demo">
    <left-menu />
    <div class="main">
      <h2>嗨库词曲库签约特权</h2>
      <br>
      <h3>一、嗨库签约音乐人</h3>
      <p>
        已签约音乐人，请前往词曲库专属通道提交作品：<el-link href="http://works.hikoon.com/" type="primary">http://works.hikoon.com/</el-link>
      </p>
      <p>
        入驻嗨库音乐works.hikoon.com进行投稿，享受以下特权：<br>
        1.独家签约，优先采购 <br>
        2.定期定量采购，收入稳定 <br>
        3.更多曝光，更多成交机会
      </p>
      <el-button type="primary" class="big-button" @click="hrefBlock('http://hikoonmusic.mikecrm.com/iFPqrBc')">申请签约入驻</el-button>
      <p style="font-size: 12px;font-weight: 400;color: #999990;line-height: 24px;">
        *申请提交成功后，将会有工作人员联系您，并提供嗨库词曲库音乐人账号。
      </p>
      <br>

      <h3>二、大众投稿</h3>
      <p>
        海葵分贝无须签约，即可快速投稿，先投稿试试。
      </p>

      <el-button type="primary" class="big-button" @click="$router.push('/demo/0')">快速投稿</el-button>
    </div>
  </div>
</template>

<script>
import LeftMenu from '../components/LeftMenu.vue'
export default {
  name: "SigningPrivileges",
  components: {
    LeftMenu,
  },
  methods: {
    hrefBlock(url){
      window.open(url, '_block')
    }
  }
}
</script>

<style scoped lang="scss">
@import '../styles/works.scss';

.demo{
  display: flex;
  justify-content: center;
  padding-top: 44px;
  padding-bottom: 70px;
}

.main{
  padding: 50px;
  box-sizing: border-box;
}
p{
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
}
</style>
